<template>
  
  <div>
    <Header/>
    <div class="headerHeight"></div>
    
    <AnnualValue/>
    <v-container fluid class="v-secondary-light-2">
      <h1 class="f-primary threeWordBanner">{{ $t('home.homeTitleLine') }}</h1>
      <div class="formBox">
        <router-link to="/" @click.native="Form()"><h2 class="btnForm">{{ $t('home.loanBtn') }}</h2></router-link>
      </div>  
    </v-container>
    <Step4/>

    <!--Large Image-->
    <v-container fluid class="image">     
      <v-row no-gutters>
        <v-col cols="12">          
        </v-col>
      </v-row>
    </v-container>
    <!--the conditions of acceptance-->
    <v-container fluid class="loanBackground">
      <v-row>
        <v-col><h2 class="loanTitle f-primary">{{ $t('home.conditionsTitleLine1') }}<br>{{ $t('home.conditionsTitleLine2') }}&nbsp;?</h2></v-col>     
      </v-row>
      <v-row class="grid-pad mt-10">
        <v-col cols="12" lg="3" class="">
          <div class="greenBorder">
            <h2 class="LoanText">{{ $t('home.conditionsBox1Line1') }}</h2>
            <h2 class="focusText f-secondary">{{ $t('home.conditionsBox1Line2') }}</h2>
            <h2 class="LoanText ">{{ $t('home.conditionsBox1Line3') }}</h2>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="">
          <div class="greenBorder">
            <h2 class="LoanText ">{{ $t('home.conditionsBox2Line1') }}</h2>
            <h2 class="focusText f-secondary">{{ $t('home.conditionsBox2Line2') }}</h2>
            <h2 class="LoanText px-5">{{ $t('home.conditionsBox2Line3') }} 3&nbsp;{{ $t('home.conditionsBox2Line4') }}</h2>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="">
          <div class="greenBorder">
            <h2 class="LoanText ">{{ $t('home.conditionsBox3Line1') }}</h2>
            <h2 class="focusText f-secondary">{{ $t('home.conditionsBox3Line2') }}</h2>
            <h2 class="LoanText ">{{ $t('home.conditionsBox3Line3') }}</h2>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="">
          <div class="greenBorder2">
            <h2 class="LoanText ">{{ $t('home.conditionsBox4Line1') }}</h2>
            <h2 class="focusText f-secondary">{{ $t('home.conditionsBox4Line2') }}</h2>
            <h2 class="LoanText ">{{ $t('home.conditionsBox4Line3') }}<br>{{ $t('home.conditionsBox4Line4') }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="txt-c px-10">
            {{ $t('home.noticeConditionsBoxLine1') }}<br>
            {{ $t('home.noticeConditionsBoxLine2') }}&nbsp;{{ $t('home.noticeConditionsBoxLine3') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="loanMiddleBorder"></v-col>
        <v-col cols="6"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="responsiveValueBox">
            <p class="fs-22 f-bold">{{ $t('home.critLine') }}</p>
            <v-btn class="v-primary mx-auto" @click="Form()"><p class="fs-12 f-white">{{ $t('home.loanBtn2') }}</p></v-btn>
            <p class="fs-22">{{ $t('home.nowLine') }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!--Value calcul and explain why-->
    <v-container fluid class="marginBoxValue pa-0" id="Calculator">
        <v-row no-gutters class="">
          <v-col cols="12" md="6" class="greenBackground">
            <div class="border-secondary outlineRectangle">
              <p class="f-primary rectangleTitle">{{ $t('home.refundsCalculTitle1') }}&nbsp;{{ $t('home.refundsCalculTitle2') }}&nbsp;{{ $t('home.refundsCalculTitle3') }}</p>
              <div class="hr1Box"><hr class="bigBorder"></div>
              <p class="mt-10">{{ $t('home.refundsCalculLine1') }}</p>
              <v-item-group v-model="payment.loanMoney" selected-class="colorSelected">
                <v-row class="f-bold">
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>250$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>300$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>400$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>500$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>600$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>750$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>800$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>900$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>1000$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>1250$</a></v-card></v-item>
                  </v-col>
                  <v-col class="loanAmount hover-card">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>1500$</a></v-card></v-item>
                  </v-col>
                </v-row>
              </v-item-group>
              <div class="hr2Box"><hr class="mt-10"></div>            
              <p class="mt-2">{{ $t('home.refundsCalculLine2') }}</p>
              <v-item-group v-model="payment.monthlyPayment" selected-class="colorSelected">
                <v-row class="f-bold">
                  <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>{{ $t('home.refundsCalculWeek1') }}</a></v-card></v-item></v-col>
                  <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>{{ $t('home.refundsCalculWeek2') }}</a></v-card></v-item></v-col>
                  <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>{{ $t('home.refundsCalculWeek3') }}</a></v-card></v-item></v-col>
                  <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><v-card :class="selectedClass" @click="toggle"><a>{{ $t('home.refundsCalculWeek4') }}</a></v-card></v-item></v-col>
                </v-row>
              </v-item-group>
              <div class="hr2Box"><hr class="mt-3"></div>    
              <p class="mt-8">{{ $t('home.refundsCalculLine3') }}<span class="f-primary f-bold"> {{this.calculateAmount()}}$</span></p>
              <p class="f-i fs-16 mt-10">{{ $t('home.refundsCalculLine4') }}</p>
              <v-btn class="v-secondary mx-auto mt-5" @click="Form()" style="width:350px;"><p class="fs-12 f-white">{{ $t('home.loanBtn3') }}</p></v-btn>
            </div>
            <p class="f-tierce noticeText">{{ $t('home.refundsCalculNotice') }}</p>
          </v-col>

          <v-col cols="12" md="6" class="grid-pad">
            <div class="boxModif">
              <h3 class="f-primary fs-24">{{ $t('home.whyPretsTitle') }}&nbsp;?</h3>             
              <p class="fs-12 mt-15">{{ $t('home.whyPretsLine1') }}&nbsp;<span class="f-bold f-i">1500$</span></p>
              <hr class="f-primary">
              <p class="fs-12">{{ $t('home.whyPretsLine2') }}&nbsp;<span class="f-bold f-i">24h</span></p>
              <hr class="f-primary">
              <p class="fs-12"><span class="f-bold f-i">{{ $t('home.whyPretsLine3A') }}</span>&nbsp;{{ $t('home.whyPretsLine3B') }}</p>
              <hr class="f-primary">
              <p class="fs-12 marginLoanOnline">{{ $t('home.whyPretsLine4') }}&nbsp;<span class="f-primary f-bold">17,5%</span></p>
              <p class="fs-8">{{ $t('home.whyPretsLine5') }}&nbsp;!</p>
              <p class="fs-7 f-secondary" id="faq">{{ $t('home.whyPretsLine6') }}</p> <!--ID faq here for scroll behavior do not change place FXGL-->
            </div>
          </v-col>
        </v-row>
    </v-container>
    <!--FAQ content-->
    <v-container fluid >
      <div class="panelPad">
        <h2 class="f-primary panelTitle">{{ $t('FAQ.title') }}</h2>
        <div >
          <v-expansion-panels >

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.firstQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.firstResponse') }}
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.secondQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.secondResponse') }} <router-link class="BlacklinkFAQ" @click.native="Form()"  to="/">{{ $t('FAQ.secondResponseLink') }}</router-link> {{ $t('FAQ.secondResponseLine1End') }} <br>
                {{ $t('FAQ.secondResponseLine2') }}
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.thirdQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.thirdResponse') }}
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.fourQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <ol>
                  <li>{{ $t('FAQ.fourResponseLine1') }}</li>
                  <li>{{ $t('FAQ.fourResponseLine2') }}</li>
                  <li>{{ $t('FAQ.fourResponseLine3') }}</li>
                  <li>{{ $t('FAQ.fourResponseLine4') }}</li>
                </ol>
                <br>{{ $t('FAQ.fourResponseLine5') }}
                <br><br>{{ $t('FAQ.fourResponseLine6') }} <router-link class="BlacklinkFAQ" @click.native="Form()" to="/">{{ $t('FAQ.fourResponseLink') }}</router-link>.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.fifthQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.fifthResponse') }}<br><br>
                {{ $t('FAQ.fifthResponseLine2') }}<router-link class="BlacklinkFAQ" @click.native="Form()"  to="/">{{ $t('FAQ.fifthResponseLink') }}</router-link>.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.sixQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.sixResponse') }}<br><br>
                {{ $t('FAQ.sixResponseLine2') }}<br><br>
                {{ $t('FAQ.sixResponseLine3') }}<router-link class="BlacklinkFAQ" @click.native="Form()"  to="/">{{ $t('FAQ.sixResponseLink') }}</router-link>.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.sevenQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.sevenResponse') }}<br><br>
                {{ $t('FAQ.sevenResponseLine2') }}
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.eighthQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.eighthResponse') }}
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.ninthQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.ninthResponse') }}
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel class="v-secondary-light-3">
              <v-expansion-panel-title>
                {{ $t('FAQ.tenthQuestion') }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ $t('FAQ.tenthResponse') }}
              </v-expansion-panel-text>
            </v-expansion-panel>

          </v-expansion-panels>
        </div>
        <p class="txt-c mt-5" >{{ $t('FAQ.subtitle') }}</p>
        <p class="txt-c fs-12">{{ $t('FAQ.contactUs') }}<a href="mailto:info@pretsenligne.ca" class="blacklink">info@pretsenligne.ca</a></p>
      </div>
    </v-container>
    <Banner/>
    <Footer/>
  </div>
  
</template>

<style scoped>
  h2 {font-weight: 200;}
  hr { width: 60%;  display: block; margin: 20px auto 20px auto; opacity: 0.25;}
  .bigBorder {border: none; height: 5px; background: gray;}
  .BlacklinkFAQ {color:#000000; font-weight: 700; text-decoration: none;}
  .boxModif {text-align: center; margin-top: 210px; }
  .btnForm { text-align: center; padding: 20px; font-size: calc(18px + 0.5vw); }
  .colorSelected{color: rgb(var(--primary)) !important;}
  .focusText {font-weight: 700; font-size: 2em; margin: 10px 0px 10px 0px;}
  .formBox { width: 350px; margin-top:30px; height: auto; display: block; margin-left: auto; margin-right: auto; background-color: white; }
  .formBox a {text-decoration: none; color: #000000;}
  .greenBackground {background-color: rgb(var(--primary)); padding: 50px 10px 20px 10px !important;}
  .greenBorder {border: 1px solid rgb(var(--primary)); margin: 10px; height: 300px; padding: 80px 0px 0px 0px; text-align: center; }
  .greenBorder2 {border: 1px solid rgb(var(--primary)); margin: 10px; height: 300px; padding: 60px 0px 0px 0px; text-align: center; }
  .hover-card:hover {opacity: 0.9; color: rgb(var(--primary));}
  .hr1Box { margin-left:auto; margin-right:auto; display:block; width:80%; }
  .hr2Box { margin-left:auto; margin-right:auto; display:block; width:90%; }
  .image { background-image: url(../../public/Resource/images/image_1.jpg); background-position: center center; background-repeat:no-repeat; background-size: cover; height:calc(31.25vw); /* 31.25vw = (600px / 19.2) = responsive for 1920*/ }
  .loanBackground {background-color: rgb(var(--secondary),0.4);}
  .loanTitle {font-weight: 700; text-align: center; font-size: 2em; margin-top: 25px;}
  .LoanText {font-size: 1.5em; }
  .loanMiddleBorder {border-right: 1px solid black; height:100px; margin: 20px 0px;}
  .marginBoxValue{margin-top: 0px;}
  .marginLoanOnline {margin-top: 40px;}
  .noticeText { text-align:center; font-size: calc(7px + 0.5vw); padding: 20px 0px 0px 0px;}
  .responsiveValueBox { margin: 0px auto 20px auto; padding-top: 0vw; padding-bottom: 1vw; text-align: center; }
  .threeWordBanner {padding-top: 30px; text-align: center; font-size: 4.5em; font-family: Tangerine; }
  .v-card--variant-elevated {box-shadow: none !important;}

  ol {padding-left: 0px; margin-top:20px;}
  ol li {font-size:18px;}
  .headerHeight{height: 104px;}
  .outlineRectangle { margin: 0px auto 0px auto; padding:0px 16px 30px 16px; width: 63%; height: auto; text-align: center; font-size: calc(16px + 0.5vw); border-radius:250px 250px 0px 0px; background-color: white; }
  .panelPad {padding: 20px 50px 100px 50px;}
  .panelTitle { font-size: 26px; font-weight: bold; margin-bottom: 40px; }
  .rectangleTitle { margin-top: 150px; font-style: italic; font-weight: 700; font-size: calc(26px + 0.5vw); }
  .v-expansion-panel-title {font-weight: 700; text-align: left;}
  .v-expansion-panel:hover{background: rgba(var(--primary),0.2) !important;}
  .loanAmount {width: 100%; min-width: 140px; height: 100%; min-height: 30px; max-height: 45px;}
  .paiementFrequency {width: 100%; min-width: 200px; height: 100%; min-height: 50px; max-height: 75px;}

@media handheld, only screen and (max-width: 700px) {
  ol {margin-top:16px; }
  ol li {font-size:14px; width:280px;}
  .boxModif {text-align: center; margin-top: 20px;}
  .formBox { width: 95%; margin-top:25px; height: auto; display: block; margin-left: auto; margin-right: auto; background-color: white; }
  .headerHeight {height: 20px !important;}
  .marginBoxValue{margin-top: 0px;}
  .marginLoanOnline{margin-top: 20px;}
  .panelPad {padding: 20px 0px 20px 0px;}
  .panelTitle {font-size: 26px; font-weight: bold; margin-bottom: 40px;}
  .responsiveValueBox {margin: 0px 0px 0px 0px; padding: 0px 10px 0px 10px; width: 100%;}
  .rectangleTitle{margin-top: 50px !important; padding: 0px 10px 0px 10px;}
}
@media handheld, only screen and (max-width: 960px) {
  .greenBackground {padding: 20px 5% 20px 5% !important;}
  .headerHeight {height: 20px !important;}
}
@media handheld, only screen and (max-width: 1279px) {
  ol li {font-size:16px;}
  .headerHeight {height: 10px !important;}
  .outlineRectangle {  width: 100% !important;}
    .rectangleTitle{margin-top: 100px !important;}
}
@media handheld, only screen and (max-width: 1700px) {
  .greenBorder {padding: 80px 0px 0px 0px;}
  .greenBorder2 {padding: 60px 0px 0px 0px;}
  .outlineRectangle { width: 80%;}
}

</style>
<script>
// @ is an alias to /src
import Header from      '@/components/Header.vue'
import Footer from      '@/components/Footer.vue'
import Banner from      '@/components/Banner.vue'
import Step4 from       '@/components/C_Home/Step4.vue'
import AnnualValue from '@/components/C_Home/AnnualValue.vue'
import HomeTranslations from '../../public/Resource/I18N/Screen_Home.json';

export default {
  name: 'Home',
  data: () => ({
    payment:{
      loanMoney: 0,
      monthlyPayment: 0,
      amount: 0.50
    },
    paiementInfo:[
      [32.16, 64.37, 64.42, 129.02],
      [38.60, 77.25, 77.25, 154.82],
      [51.46, 102.99, 103.07, 205.25],
      [64.33, 128.74, 128.84, 258.03],
      [77.20, 154.49, 154.61, 309.64],
      [96.49, 193.11, 193.26, 387.05],
      [102.93, 205.99, 206.15, 412.85],
      [115.79, 231.74, 231.91, 464.46],
      [128.66, 257.68, 257.68, 516.06],
      [160.82, 321.86, 322.10, 645.08],
      [192.99, 386.23, 385.52, 774.10]
    ],
  }),
  mounted: async function () {
    this.$i18n.mergeLocaleMessage('fr', HomeTranslations.fr);
    this.$i18n.mergeLocaleMessage('en', HomeTranslations.en);
  },
  methods:{
    calculateAmount() {

      if(this.payment.loanMoney != undefined){
        return this.paiementInfo[this.payment.loanMoney][this.payment.monthlyPayment];
      }else {return 0;}

    },
    currentLang() {
        return this.$i18n.locale;
    },
    Form() {
      let lang = this.currentLang();

      console.log(lang);

      if(lang == 'en'){ window.open('https://applications.pretsenligne.ca/EN/', '_blank'); }
      else{ window.open('https://applications.pretsenligne.ca/', '_blank'); }
    },
  },
  components: {
    Header,
    Footer,
    Banner,
    Step4,
    AnnualValue
},
}
</script>
