<template>
  <v-container id="headerEnd" class="headerStyle" fluid>
        <div class="">
          <div class="d-none d-lg-flex">
            <v-row no-gutters class="v-primary grid-pad">
              <v-col cols="12" md="2" lg="2" xl="1">
                <div class="" style="" outlined tile>
                  <div class="circleStyle">
                    <div class="logoStyle">
                      <router-link to="/"><v-img src="../../public/Resource/images/Main_logo-2.png" class="" style="height:35%;"></v-img></router-link>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="7">
                <div class="pa-2" outlined tile>
                  <div class="headerTtile">
                    <h1 class="f-i">{{ $t('header.firstLine') }}</h1>
                    <h1 class="f-i txt-r" style="margin-top:-3%;">{{ $t('header.secondLine') }}</h1>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="4" xl="4">
                <div class="pa-2" outlined tile style="position: relative;">
                  <v-btn class="loanBtn borderButton" @click="Form()" ><p class="buttonTitle">{{ $t('header.loanBtn') }}</p></v-btn><br>
                  <p class="txt-c txtjoin">{{ $t('header.joinLine') }} <a href="mailto:info@pretsenligne.ca" class="blacklink">info@pretsenligne.ca</a></p>
                </div>              
              </v-col>
            </v-row>
          </div>

          <v-row no-gutters class="headerLinkStyle d-none d-lg-flex">
            <v-col cols="12">
              <div>
                <div class="txtPlace">
                  <router-link to="/" class="linkModif"><h2>{{ $t('header.homeLine') }}</h2></router-link>             
                  <h2 class="pipeModif">|</h2>
                  <router-link class="linkModif" to="/pretRapide"><h2>{{ $t('header.quickLoanLine') }}</h2></router-link>
                  <h2 class="pipeModif">|</h2>
                  <div class="linkModif">
                    <h2 style="cursor: pointer;" v-if="this.$i18n.locale != 'fr'" @click="switchLanguage()">FR</h2>
                    <h2 style="cursor: pointer;" v-if="this.$i18n.locale != 'en'" @click="switchLanguage()">EN</h2>                
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="d-none d-lg-flex">
            <v-col cols="12" class="d-flex justify-end pr-8">
              <div v-if="!calculatorOpened" class="d-none d-sm-flex shapeBox pointer" @click="slideLoanBox()" style="font-size: 18px; position:fixed;">
                <v-icon class="iconMb" style="margin: 0px auto;">mdi-calculator</v-icon>
              </div>
            </v-col>
            
            <div id="loanCalculator" class="loanBox">
              <div class=" v-primary-light-8 txt-c f-tierce loanBorderBox" >
                  <v-icon v-if="calculatorOpened" class="iconMb" @click="slideLoanBox()" style="width: 40px; height: 40px; font-size: 30px; right:26px; margin-top: 4px; position: fixed;">mdi-close</v-icon>
                <v-card-text  class="">
                  <h2 class=" f-i mt-3 fs-26">{{ $t('header.calculatorTitle') }}</h2>
                  <div style="display:block; margin-left:auto; margin-right:auto; width:80%;"><hr class="bigBorder"></div>
                  <p class="mt-3 fs-18">{{ $t('header.calculatorLine1') }}</p>
                  <v-item-group v-model="payment.loanMoney" selected-class="colorSelected">
                    <v-row class="f-bold fs-14 mt-0">
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>250$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>300$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>400$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>500$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>600$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>750$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>800$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>900$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>1000$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>1250$</a></div></v-item>
                      </v-col>
                      <v-col class="loanAmount hover-card">
                        <v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>1500$</a></div></v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                  <hr class="mt-10">
                  <p class=" fs-18">{{ $t('header.calculatorLine2') }}</p>
                  <v-item-group v-model="payment.monthlyPayment" selected-class="colorSelected">
                    <v-row class="f-bold fs-14 mt-0">
                      <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>{{ $t('header.calculatorWeek1') }}</a></div></v-item></v-col>
                      <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>{{ $t('header.calculatorWeek2') }}</a></div></v-item></v-col>
                      <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>{{ $t('header.calculatorWeek3') }}</a></div></v-item></v-col>
                      <v-col class="paiementFrequency hover-card"><v-item v-slot="{ isSelected, selectedClass, toggle }"><div :class="selectedClass" @click="toggle"><a>{{ $t('header.calculatorWeek4') }}</a></div></v-item></v-col>
                    </v-row>
                  </v-item-group>
                  <hr class="mt-10">
                  <p class="mt-3 fs-18">{{ $t('header.calculatorLine3') }}<span class="fs-26 f-bold f-black"> {{this.calculateAmount()}}$</span></p>
                  <p class="f-i fs-16 mt-3">{{ $t('header.calculatorLine4') }}</p>
                  </v-card-text>
                  <v-card-text class="mt-n3">
                  <v-btn class="v-primary loanBtn2" @click="Form()">
                    <p class="f-white fs-16">{{ $t('header.calculatorLoanBtn') }}</p>
                  </v-btn>
                  </v-card-text>
                  <v-card-text class="mt-n3">
                    <p class="fs-7">{{ $t('header.calculatorNotice') }}</p>
                  </v-card-text>
              </div>
            </div>
          </v-row>
          <v-row no-gutters class="d-flex d-lg-none ">
              <v-app-bar ref="menuMobile" class="v-primary pa-0">
                <router-link to="/"><v-img src="../../public/Resource/images/Main_logo-2.png" class="logoStyleMobile"></v-img></router-link>
              <v-app-bar-nav-icon class="menuIconMobile" variant="text" @click.stop="this.drawer = !this.drawer"></v-app-bar-nav-icon>

              <h2 class="logoMobile">Prêt en ligne</h2>
              <v-spacer></v-spacer>
              <router-link to="/#Calculator" class="linkModif"><v-icon>mdi-calculator</v-icon></router-link>



            </v-app-bar>

              <v-navigation-drawer v-model="this.drawer" bottom temporary style="margin-top:40px; height:100%;"> <!--can't take a class-->
                <v-list>
                  <v-list-item><router-link to="/" class="linkModif"><h2>Accueil</h2></router-link></v-list-item>
                  <v-list-item><router-link class="linkModif" to="/pretRapide"><h2>Prêts rapides</h2></router-link></v-list-item>
                  <v-list-item><router-link  @click.native="Form()" to="/" class="linkModif"><h2>Demandez votre prêt</h2></router-link></v-list-item>
                  <v-list-item v-if="this.$i18n.locale != 'en'"><h2 class="linkModif" @click="switchLanguage()">EN</h2></v-list-item>
                  <v-list-item v-if="this.$i18n.locale != 'fr'"><h2 class="linkModif" @click="switchLanguage()">FR</h2></v-list-item>
                </v-list>
              </v-navigation-drawer>
          </v-row>
        </div>
        
  </v-container>
</template>


<style scoped>
  .headerStyle {padding:0px; position:fixed; z-index:1000; top:0px;}
  .headerStyle h1 { font-size: 2em;}
  .headerLinkStyle {height:40px; padding:5px 20px 5px 5px;  background-color: rgb(var(--secondary));}
  .loanBtn {margin-top: 0px !important;}/*Original btn in main css modified*/
  .headerTtile {width:550px; margin-left: -2%; font-family: Cormorant;}
  .circleStyle {margin:8px auto 8px auto; display: block; height:100px; width:100px; background-color: #ffffff; border-radius: 50%;}
  .logoStyle {padding: 10px;}
  .logoStyleMobile {width:30px; margin-bottom:22px;}
  .txtPlace {text-align: right;}
  .txtPlace h2 {font-size: 20px;}
  .linkModif {text-decoration:none; color:black; display: inline-block; padding-bottom: 22px;}
  .pipeModif {display: inline-block; margin:0px 20px;}
  .loanBox { position:absolute; right: 16px; top: 156px; z-index: -11; width: 100%; max-width: 600px;}
  .loanBorderBox { border: 5px solid rgb(var(--secondary)); height: auto; }
  .buttonTitle{ font-size: calc(10px + 0.5vw); text-align: center; padding:10px 0px; }
  .colorSelected{ color: rgb(var(--secondary)) !important; }
  .bigBorder{ border: none; height: 2px; background: black; }
  .hover-card:hover { opacity: 0.9; color: rgb(var(--secondary)); }
  .loanBtn2 { height:50px !important; border-radius: 12px !important; width:280px+1.5vw; }
  .shapeBox { top:106px; height: 50px; width: 50px; margin: 50px auto; position: relative; overflow: hidden; border: solid 5px rgb(var(--primary)); border-bottom: none; text-align: center; padding-top:3px; }
  .shapeBox:before,.shapeBox:after { content: ''; display: block; height: 94%; width: 200%; transform: rotate(20deg); box-shadow: 17px 0 0 5px rgb(var(--primary)); position: absolute; top: 1px; right: -135%; }
  .shapeBox:after { transform: rotate(-20deg);left: -135%;box-shadow: -17px 0 0 5px rgb(var(--primary));}
  .pointer { cursor: pointer; }
  .txtjoin { text-align: center; margin-top: -4%; font-size: calc(10px + 0.5vw) ;}
  .logoMobile { padding-bottom: 22px; }
  .menuIconMobile { padding-bottom: 22px; }
  .loanAmount {width: 100%; min-width: 140px; height: 100%; min-height: 30px; max-height: 35px;}
  .paiementFrequency {width: 100%; min-width: 200px; height: 100%; min-height: 30px; max-height: 40px;}

  @media handheld, only screen and (max-width: 1279px) {
    .v-toolbar{ height: 40px !important; }
    .headerMobile { height: 40px !important; }
    .headerLinkStyle { height:50px; padding:8px; background-color: rgb(var(--primary)); }
    .headerTtile { width:415px; }
    .headerTtile h1 { font-size: 1.5em; }
    .loanBtn {display: block; margin-left: auto; margin-right: auto; margin-top: 10px !important; padding-top:10px ; height:50px !important; float: none;} /*Original btn in main css modified*/
    .pipeModif {display: inline-block !important; margin:0px 20px;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .headerTtile {width:330px; }
    .headerTtile h1 { font-size: 1.2em;}
    .loanBtn {display: block; margin-left: auto; margin-right: auto; margin-top: 10px !important; padding-top:12px ; width:300px; height:50px !important; float: none;} /*Original btn in main css modified*/
    .loanBorderBox { border: 5px solid rgb(var(--secondary)); border-radius:00px; height: 530px; }
    .loanBox { position:absolute; right: 16px; top: 270px; z-index: -11; }
    .shapeBox { top:170px; }
  }
  @media handheld, only screen and (max-width: 700px) {
    .headerTtile {width:330px; }
    .headerTtile h1 { font-size: 1.2em;}
    .headerLinkStyle h2 { font-size: 18px !important;}
    .loanBtn {display: block; margin-left: auto; margin-right: auto; margin-top: 0px !important; padding: 5px 0px 5px 0px;  ;height:auto!important; float: none;} /*Original btn in main css modified*/
    .f-i{ width: 100%;}
    .buttonTitle {font-size: 18px; text-align: center;}
    .circleStyle {margin:8px auto 8px auto; display: block; height:100px; width:100px; background-color: #ffffff; border-radius: 50%;}
    .pipeModif {display: inline-block !important; margin:0px 10px;}
    .iconMb {display: inline-block !important; margin-top: -8px;}
    .loanBox { position:absolute; right: 0px; top: -400px; z-index: -11; }
  }
</style>

<script>
  import translations from '../../public/Resource/I18N/traduction.json';
  export default {
    name: 'Header',

    data: () => ({
      drawer: false,
      payment:{
        loanMoney: 0,
        monthlyPayment: 0,
        amount: 0.50
      },
      calculatorOpened:false,
      mobileCalculatorOpened:false,
      heightToolbar: 50,
      paiementInfo:[
        [32.16, 64.37, 64.42, 129.02],
        [38.60, 77.25, 77.25, 154.82],
        [51.46, 102.99, 103.07, 205.25],
        [64.33, 128.74, 128.84, 258.03],
        [77.20, 154.49, 154.61, 309.64],
        [96.49, 193.11, 193.26, 387.05],
        [102.93, 205.99, 206.15, 412.85],
        [115.79, 231.74, 231.91, 464.46],
        [128.66, 257.68, 257.68, 516.06],
        [160.82, 321.86, 322.10, 645.08],
        [192.99, 386.23, 385.52, 774.10]
      ],
    }),
    mounted() {
      
        var h = window.innerWidth;
        console.log(h);
        if(h <= 960){
          //this.mobileLoanBox();
        } else {
          this.slideLoanBox();
        }

      window.addEventListener("scroll", this.onScroll);

      this.$i18n.mergeLocaleMessage('fr', translations.fr);
      this.$i18n.mergeLocaleMessage('en', translations.en);

    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.onScroll)
    },
    computed(){
      window.addEventListener("scroll", this.mobileMenu);
    },
    methods:{
      calculateAmount() {

        if(this.payment.loanMoney != undefined){
          return this.paiementInfo[this.payment.loanMoney][this.payment.monthlyPayment];
        }else {return 0;}
        
      },
      slideLoanBox() {
        var c = document.getElementById("loanCalculator");
        var e = document.getElementById("headerEnd");
        var h = window.innerWidth;
        
        if(this.calculatorOpened == true){
          if(h <= 960){
            c.style.top = '-600px';
          } else {
            c.style.top = '-600px';
          }
          this.calculatorOpened = false;
        } else {
          c.style.top = e.style.bottom;
          this.calculatorOpened = true;
        }
        
      },
      mobileLoanBox(){
        if(this.mobileCalculatorOpened){
          this.mobileCalculatorOpened = false;
        } else { this.mobileCalculatorOpened = true;}
        
      },
      onScroll(e) {
        var h = window.innerWidth;
        console.log(h);
        if(h <= 960){
          
        } else {
          if(this.calculatorOpened){
            this.slideLoanBox();
          }
        }
      },
      mobileMenu(e){
        var navbar = this.$refs[this.mobileMenu];
        var sticky = navbar.offsetTop;
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky");
        } else {
            navbar.classList.remove("sticky");
        }
      },
      switchLanguage: function () {
         this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en';     
      },
      currentLang() {
        return this.$i18n.locale;
      },
      Form() {
        let lang = this.currentLang();

        console.log(lang);

        if(lang == 'en'){ window.open('https://applications.pretsenligne.ca/EN/', '_blank'); }
        else{ window.open('https://applications.pretsenligne.ca/', '_blank'); }
      }

    },
    component: {

    }
    
  }
</script>

