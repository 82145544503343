import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import application from './../public/Resource/scripts/app.js'
import { createI18n } from 'vue-i18n'
import $event from './event.js'
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'




const i18n = createI18n({

  locale: 'en',
  allowComposition: true, // you need to specify that!
  messages: {
    en: {
      hello: 'Simple. Snap. Advantageous.'
    },
    fr: {
      hello: 'Simple. Instantané. Avantageux.'
    }
  }
})
/*
createApp(App)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(application)
  .mount('#app')*/
  const app = createApp(App)
  app.use(router)
  app.use(vuetify)
  if(!$App.event){ $App.event = $event; }
  app.use(i18n)
  app.use(application)
  app.mount('#app')