<template>

  <div class="">
    <Header/>
    <div class="headerHeight"></div>
    <v-container class="line-h-1-2 txt-j mt-8">

      <v-row>
        <v-col cols="1" lg="5" class="borderTop">
          <div class=""></div>
        </v-col>
        <v-col cols="10" lg="2" class="pa-0">
          <div class="">
            <h1 class="fs-26 f-i txt-c">{{ $t('quickLoan.title') }}</h1>
          </div>
        </v-col>
        <v-col cols="1" lg="5" class="borderBot">
          <div class=""></div>
        </v-col>
      </v-row>

        <div class="">
            <h2 class="m-fs-par">{{ $t('quickLoan.subtitle1') }}</h2>
            <p class="m-txt-top">{{ $t('quickLoan.line1A') }}<span class="f-bold">{{ $t('quickLoan.line1B') }}</span>{{ $t('quickLoan.line1C') }}<span class="f-bold">{{ $t('quickLoan.line1D') }}</span>{{ $t('quickLoan.line1E') }}<span class="f-bold">{{ $t('quickLoan.line1F') }}</span>{{ $t('quickLoan.line1G') }}<span class="f-bold">{{ $t('quickLoan.line1H') }}</span>{{ $t('quickLoan.line1I') }}</p>
        </div>
        <div>
            <h2 class="m-fs-par">{{ $t('quickLoan.subtitle2') }}</h2>
            <p class="m-txt-top">{{ $t('quickLoan.line2A') }}<span class="f-bold">{{ $t('quickLoan.line2B') }}</span>{{ $t('quickLoan.line2C') }}<span class="f-bold">{{ $t('quickLoan.line2D') }}</span>{{ $t('quickLoan.line2E') }}<router-link class="link" to="/"  @click.native="Form()">{{ $t('quickLoan.line2F') }}</router-link>{{ $t('quickLoan.line2G') }}<span class="f-bold">{{ $t('quickLoan.line2H') }}</span>{{ $t('quickLoan.line2I') }}<span class="f-bold">{{ $t('quickLoan.line2J') }}</span>.</p>
        </div>
        <v-row>
            <v-col cols="6">
                <div class="mt-100">
                    <p>{{ $t('quickLoan.line3') }}<router-link to="/#faq" class="link">{{ $t('quickLoan.line3A') }}</router-link> !</p>  
                </div>
            </v-col>
            <v-col cols="6">
                <div class=" rounded-circle image imageDimension" style=""></div>
            </v-col>
        </v-row>
    </v-container>
    
    <Banner/>
    <Footer/>

  </div>
  
</template>
<style scoped>
  .m-txt-top {margin-top:4px;}
  .m-fs-par {margin-top:40px; font-size: calc(12px + 0.5vw);}
  .image { margin-top: 32px; padding-bottom: 50px; background-image: url(../../public/Resource/images/image_2.jpg); background-position: center center; background-repeat:no-repeat; background-size: cover; }
  .imageDimension { width:20vw; height: 20vw; min-width:200px; min-height:200px; }
  p { font-size: calc(8px + 0.5vw); }
    .headerHeight{height: 104px;}
  @media handheld, only screen and (max-width: 1264px) {
    .headerHeight {height: 0px !important;}
  }
  @media handheld, only screen and (max-width: 700px) {
    .m-fs-par {margin-top:40px; font-size: calc(16px + 0.5vw);}
    p { font-size: calc(12px + 0.5vw); }
    .headerHeight {height: 0px !important;}
  }

</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import QuickLoanTranslations from '../../public/Resource/I18N/screen_QuickLoan.json';

export default {
  name: 'QuickLoan',
  components: {
    Header,
    Footer,
    Banner
  },
  mounted() {
    this.$i18n.mergeLocaleMessage('fr', QuickLoanTranslations.fr);
    this.$i18n.mergeLocaleMessage('en', QuickLoanTranslations.en);

  },
  methods:{
    currentLang() {
        return this.$i18n.locale;
    },
    Form() {
      let lang = this.currentLang();

      console.log(lang);

      if(lang == 'en'){ window.open('https://applications.pretsenligne.ca/EN/', '_blank'); }
      else{ window.open('https://applications.pretsenligne.ca/', '_blank'); }
    },
  },
}
</script>