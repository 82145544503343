<template>
    <v-container fluid class="" style="padding: 0px !important;"> <!--Ne pas enlever padding FXGL-->
      <v-row class="image">
        <!--text-->
        <v-col cols="12" lg="6">
          <div class="topHeaderBox grid-pad">
            <v-row class="v-tierce box">
              <v-col cols="3" class="colDisplay"><v-img class="icon" src="../../../public/Resource/images/doc-icon.png"></v-img></v-col>
              <v-col cols="9" class="colDisplay"><h2 class="fontModif">{{ $t('annualValue.firstLine1') }}<br> {{ $t('annualValue.firstLine2') }}&nbsp;{{ $t('annualValue.firstLine3') }}</h2></v-col>         
            </v-row>
            <v-row class="v-tierce box">
              <v-col cols="3" class="colDisplay"><v-img class="icon" src="../../../public/Resource/images/clock-icon.png"></v-img></v-col>
              <v-col cols="9" class="colDisplay"><h2 class="fontModif">{{ $t('annualValue.secondLine1') }}<br>{{ $t('annualValue.secondLine2') }}&nbsp;{{ $t('annualValue.secondLine3') }}</h2></v-col>         
            </v-row> 
            <v-row class="v-tierce box">
              <v-col cols="3" class="colDisplay"><v-img class="icon" src="../../../public/Resource/images/loop-icon.png"></v-img></v-col>
              <v-col cols="9" class="colDisplay"><h2 class="fontModif">{{ $t('annualValue.thirdLine1') }}<br>{{ $t('annualValue.thirdLine2') }}</h2></v-col>         
            </v-row>
            <v-row class="v-tierce box">
              <v-col cols="3" class="colDisplay"><v-img class="icon" src="../../../public/Resource/images/dollars-icon.png"></v-img></v-col>
              <v-col cols="9" class="colDisplay"><h2 class="fontModif fontmobilefix">{{ $t('annualValue.fourthLine1') }}&nbsp;{{ $t('annualValue.fourthLine2') }}&nbsp;{{ $t('annualValue.fourthLine3') }}</h2></v-col>         
            </v-row>
             
          </div>
          <div class="valueBox">
            <h2 class="valueText">{{ $t('annualValue.lastLine1') }}<span class="f-primary fs-36"> 17,5%&nbsp;</span>{{ $t('annualValue.lastLine2') }}</h2>
          </div>
        </v-col>
        <v-col cols="12" md="6"></v-col>

      </v-row>
      <v-row>
        <p class="smalltitle">Photo by micheile dot com on&nbsp;Unsplash</p>
      </v-row>
    </v-container>
</template>

<style scoped>
  .colDisplay {display: inline-block;}
  .valueText { text-align: center; font-family: Arapey; font-size: calc(24px + 0.5vw);}
  .topHeaderBox {margin-top: 40px;}
  .box {height: auto; width: auto; display: block; margin: 10px auto 10px auto;  border-radius: 50px; padding: 5px 0px 0px 0px;}
  .icon {display:block; margin-left: auto; margin-right: auto; height: auto; width: 45px;}
  .smalltitle {opacity: 0.4; color: gray; padding: 0px 0px 0px 20px; margin-top: -1%;}
  .fontModif { font-size: 1.5em; text-align: center; font-weight: 200; padding-bottom:10px;}
  .valueBox { display: block; margin: 100px auto 50px auto; padding: 1vw auto 1vw auto !important; background-image: url('../../../public/Resource/images/Border.png'); background-size: contain; background-repeat: no-repeat; background-position: center; }
  .image { background-image: url(../../../public/Resource/images/header.jpg); background-position: center center; background-repeat:no-repeat; background-size: cover; height: auto; }
@media handheld, only screen and (max-width: 700px) {
  .smalltitle {opacity: 0.4; color: gray; padding: 0px 0px 0px 20px; margin-top: -2%; font-size: 14px;}
  .image { height:calc(50.25vw); box-shadow: none; } /* 31.25vw = (600px / 19.2) = responsive for 1920*/
  .fontModif { margin: -5% 0px 0px 0px !important; text-align: center; font-size: 0.95em !important; }
  .valueBox {height: 100px; background-image: none;}
  .box {width: 95% !important;}
  .fontmobilefix {padding-top: 10px;}
}
@media handheld, only screen and (max-width: 960px) {
  .image {   box-shadow: inset 10px 0px 10px 0px rgba(000,000,000,0.5); height: calc(725px + 0.5vw); }
}
@media handheld, only screen and (max-width: 1380px) {
  .homeTitle { margin: 16px 0px 20px 0px; text-align: left; font-weight: 200; font-size: calc(26px + 0.5vw); }
}
@media handheld, only screen and (max-width: 1700px) {
  .fontModif { font-size: 1em; }
  .box {width: 85%;}
}
</style>

<script>
  export default {
    name: 'AunnualValue',

    data: () => ({
    
    }),
    methods:{

    },
    component: {

    }
  }
</script>