import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import LegalNotice from '../views/LegalNotice.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import QuickLoan from '../views/QuickLoan.vue'
import Forms from '../views/Forms.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/MentionLegal',
    name: 'LegalNotice',
    component: LegalNotice
  },
  {
    path: '/politiqueConfidentialite',
    name: 'politique',
    component: PrivacyPolicy
  },
  {
    path: '/pretRapide',
    name: 'QuickLoan',
    component: QuickLoan
  },
  {
    path: '/Formulaire',
    name: 'Forms',
    component: Forms
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router
