<template>
  <div class="pa-0 line-h-1">
    <v-container fluid class="v-primary  bannerHeight"> 
        <v-row no-gutters class="grid-pad spacingBanner">
          <v-col cols="12" md="8">
            <div class="pa-2"  outlined tile>
              <div class="headerTtile">
                <h1 class="f-i">{{ $t('banner.firstLine') }}</h1>
                <h1 class="f-i txt-r">{{ $t('banner.secondLine') }}</h1>
              </div>              
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pa-2" outlined tile>
               <v-btn class="loanBtn borderButton" @click="Form()" to="/"><p class="buttonTitle">{{ $t('banner.loanBtn') }}</p></v-btn>  
            </div>
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<style scoped>
  .bannerHeight {height: 150px;}
  .headerTtile {width:550px; font-family: Cormorant;}
  .headerTtile h1 { font-size: 2em;}
  .spacingBanner {margin-top: 28px !important;}
  .buttonTitle{font-size: calc(10px + 0.5vw); text-align: center; padding:10px 0px;}
  
  @media handheld, only screen and (max-width: 1264px) {
    .headerTtile {width:415px; }
    .headerTtile h1 { font-size: 1.5em;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .bannerHeight {height: 200px;}
    .loanBtn {display: block; margin-left: auto; margin-right: auto; margin-top: 10px !important;  padding: 5px 0px 5px 0px; width:300px;  height:50px !important; float: none;} /*Original btn in main css modified*/
    .headerTtile {width:550px; }
    .headerTtile h1 { font-size: 2em;}
    }
    @media handheld, only screen and (max-width: 700px) {
    .bannerHeight {height: 175px;}
    .spacingBanner {margin-top: 5px !important;}
    .headerTtile {width:100%; }
    .headerTtile h1 { font-size: 1.2em;}
    .loanBtn {display: block; margin-left: auto; margin-right: auto; margin-top: 0px !important; margin-bottom: 10px !important; padding: 5px 0px 5px 0px;  ;height:auto!important; float: none;} /*Original btn in main css modified*/
  }

</style>
<script>
  export default {
    name: 'Footer',

    data: () => ({
    
    }),
    methods:{
      currentLang() {
        return this.$i18n.locale;
      },
      Form() {
        let lang = this.currentLang();

        console.log(lang);

        if(lang == 'en'){ window.open('https://applications.pretsenligne.ca/EN/', '_blank'); }
        else{ window.open('https://applications.pretsenligne.ca/', '_blank'); }
      },
    },
    component: {

    }
  }
</script>
