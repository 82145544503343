<template>
  <v-footer class="pa-0 line-h-1">
    <v-container fluid> 
        <v-row no-gutters>
          <v-col cols="12" md="4" class="borderRight">
            <div class="mt-footer" outlined tile>
              <p class="fs-18 mx-auto" style="display: block; width:250px;">{{ $t('footer.firstColFirstLine') }}<br>
              <v-icon large> mdi-email-outline </v-icon>: <a href="mailto:info@pretsenligne.ca" class="footerlink">info@pretsenligne.ca</a></p>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div outlined tile>
              <div class="mt-footer mx-auto" style="display: block; width:250px;">
                <router-link to="/politiqueConfidentialite" style="text-decoration:none; color:black;"><a class="fs-16 HoverLink">{{ $t('footer.secondColFirstLine') }}</a></router-link><br>
                <router-link to="/MentionLegal" style="text-decoration:none; color:black;"><a class="fs-16 HoverLink">{{ $t('footer.secondColSecondLine') }}</a></router-link>
                <div class="cookieLink "><p @click="openCookie()" class="" v-html="$t('footer.CookieLink')"></p></div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pa-2 " outlined tile>
              <div class="mx-auto sizeImg">
                <router-link to="/"><v-img src="../../public/Resource/images/Main_logo.png"></v-img></router-link>
              </div>
            </div>
          </v-col>
        </v-row>
        <div>
          <p class="footerEnd"><v-icon class="iconMb">mdi-lock</v-icon>{{ $t('footer.lastLine') }}</p>
        </div>
    </v-container>  
  </v-footer>
</template>

<style scoped>
  .fs-16 {font-size: 16px !important;}
  .fs-18 {font-size: 18px !important;}
  .borderRight {border-right:1px solid black;}
  .mt-footer {margin-top: 8%;}
  .footerEnd {text-align:right;margin: 0px 180px 0 0; font-size: calc(6px + 0.5vw);}
  .iconMb {margin-bottom:4px;}
  .sizeImg{display: block; height:auto; width:100px;}
  .footerlink {color: black; text-decoration:none;}
  .cookieLink { text-decoration: none ; cursor: pointer; display: block; text-align: left;}
  .cookieLink:hover p { text-decoration: underline !important; color: rgb(var(--primary)); }
  .HoverLink:hover { text-decoration: underline !important; color: rgb(var(--primary)); }

  @media handheld, only screen and (max-width: 700px) {
    .footerEnd {margin: 24px 155px 0 0;}
    .iconMb {margin-bottom:2px;}
  }

  @media handheld, only screen and (max-width: 960px) {
    .borderRight {border-right:none;}
    .mt-footer {margin-top: 2%;}
    .iconMb {margin-bottom:2px;}
  }

</style>
<script>
  export default {
    name: 'Footer',

    data: () => ({
    
    }),
    methods:{
      openCookie: function(){
          $App.event.$emit('openCookie');
      }
    },
    component: {

    }
  }
</script>
