<template>
  <v-app>
    <v-main>
      <Cookie/>

      <noscript v-if="authorizations && authorizations.Advertising == true"><iframe src="//www.googletagmanager.com/ns.html?id=GTM-KW2QFGDF" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <router-view/>
    </v-main>
  </v-app>
</template>

<style >
  @import '../public/App_Themes/Main/Main.css';

</style>
<script>
  import Cookie from '../src/components/cookie.vue';
export default {
  name: 'App',

  data: () => ({
    authorizations:{
        Essential: true,
        Performance: false,
        Personalization: false,
        Advertising: false,
    },
  }),
  mounted: async function () {
    this.VisibilityTools();
  },
  methods:{
    VisibilityTools: function(){
      let getAuthorizations = JSON.parse(localStorage.getItem("authorizationCookies"));
      if(getAuthorizations) { this.authorizations = JSON.parse(localStorage.getItem("authorizationCookies")); }     
      if(this.authorizations && this.authorizations.Advertising == true) { GMTodemWorld(window,document,'script','dataLayer','GTM-KW2QFGDF'); }
      
      /*
      if(this.authorizations){
        //let monScript;
        //let googleManager = document.getElementById("googleManager");
        //let googleScript = document.getElementById("googleScript");
        if(this.authorizations.Advertising == true) {
          
          if(!googleScript) {
            monScript = document.createElement('script');
            monScript.id = 'googleScript';
            monScript.setAttribute('src', '/Resource/scripts/tools.js');
            .head.appendChild(monScript);
          }
        } /*else {
          if (googleScript) { googleScript.remove(); monScript = null; }
          if (googleManager) { googleManager.remove(); }
        }
      }*/

    }
  },
  components: {
    Cookie,
  }
}
</script>
