<template>

  <div class="">
    <Header/>
    <div class="headerHeight"></div>
    <v-container class="mt-8">
      <v-row>
        <v-col cols="1" lg="4" class="borderTop">
          <div class=""></div>
        </v-col>
        <v-col cols="10" lg="4" class="pa-0">
          <div class="">
            <h1 class="fs-26 f-i txt-c">{{ $t('privacyPolicy.title') }}</h1>
          </div>
        </v-col>
        <v-col cols="1" lg="4" class="borderBot">
          <div class=""></div>
        </v-col>
      </v-row>

      <div class="fs-8 line-h-1-2 txt-j mt-16">
        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par1Subtitle') }}</h3>
        <p class="m-txt-top"><span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par1Line1A') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('privacyPolicy.par1Line1B') }}</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par2Subtitle') }}</h3>
        <p class="m-txt-top"><span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par2Line1') }}</p> 
        <p class="m-par">{{ $t('privacyPolicy.par2Line2A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par2Line2B') }}</p> <!--Have to modif Pret en Ligne?-->

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par3Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par3Line1A') }} <span class="f-i">{{ $t('privacyPolicy.par3Line1B') }}</span>{{ $t('privacyPolicy.par3Line1C') }} </p>
        
        <p class="m-par">{{ $t('privacyPolicy.par3Line2') }}</p>
          <p>{{ $t('privacyPolicy.par3Line3') }}</p>
          <p>{{ $t('privacyPolicy.par3Line4') }}</p>
          <p>{{ $t('privacyPolicy.par3Line5') }}</p>
          <p>{{ $t('privacyPolicy.par3Line6') }}</p>
          <p>{{ $t('privacyPolicy.par3Line7') }}</p>
          <p>{{ $t('privacyPolicy.par3Line8') }}</p>

        <p class="m-par">{{ $t('privacyPolicy.par3Line9') }}</p>
        <p>{{ $t('privacyPolicy.par3Line10A') }} <span class="f-i">{{ $t('privacyPolicy.par3Line10B') }}</span></p>
        <p>{{ $t('privacyPolicy.par3Line11') }}</p>
          <ul>
            <li>{{ $t('privacyPolicy.par3Line12li1') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li2') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li3') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li4') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li5') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li6') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li7') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li8') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li9') }}</li>
            <li>{{ $t('privacyPolicy.par3Line12li10') }}</li>
          </ul>
        
        <p class="m-par">{{ $t('privacyPolicy.par3Line13') }}</p>


        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par4Subtitle') }}</h3>
        <p class="m-txt-top"><span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par4Line1') }}<a href="http://www.google.com/intl/fr/policies/privacy/partners" class="link">http ://www.google.com/intl/fr/policies/privacy/partners</a>.</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par5Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par5Line1') }}</p>

        <p class="m-par">{{ $t('privacyPolicy.par5Line2A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par5Line2B') }}</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par6Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par6Line1') }}<a  href="http://www.legisquebec.gouv.qc.ca/fr/showdoc/cs/P-39.1" class="link">(http ://www.legisquebec.gouv.qc.ca/fr/showdoc/cs/P-39.1)</a>.</p>
        
        <p class="m-par">{{ $t('privacyPolicy.par6Line2A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par6Line2B') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par6Line2C') }}</p>
        
        <p class="m-par">{{ $t('privacyPolicy.par6Line3A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par6Line3B') }}</p>

        <p class="m-par">{{ $t('privacyPolicy.par6Line4A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par6Line4B') }}</p>
          <ul>
            <li>{{ $t('privacyPolicy.par6Line5li1') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li2') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li3') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li4') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li5') }}<span class="f-i">Prêt en ligne</span> ;</li>
            <li>{{ $t('privacyPolicy.par6Line5li6A') }}<span class="f-i">Prêt en ligne</span>){{ $t('privacyPolicy.par6Line5li6B') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li7') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li8') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li9') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li10') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li11') }}</li>
            <li>{{ $t('privacyPolicy.par6Line5li12') }}</li>
          </ul>

        <p class="m-par"><span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par6Line6A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par6Line6B') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par6Line6C') }}</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par7Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par7Line1') }}</p>

        <p class="m-par">{{ $t('privacyPolicy.par7Line2A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par7Line2B') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par7Line2C') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par7Line2D') }}</p>

        <h3 class="m-fsi-par txt-l">{{ $t('privacyPolicy.par8Subtitle') }}</h3>
        <p class="m-txt-top"><span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par8Line1') }}</p>

        <p class="m-par"><span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par8Line2') }}</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par9Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par9Line1A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par9Line1B') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par9Line1C') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par9Line1D') }}</p>

        <p class="m-par">{{ $t('privacyPolicy.par9Line2A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par9Line2B') }}</p>
        
        <p class="m-par">{{ $t('privacyPolicy.par9Line3') }}</p>
        
        <p class="m-par">{{ $t('privacyPolicy.par9Line4') }}</p>
        
        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par10Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par10Line1') }}</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par11Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par11Line1A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par11Line1B') }}</p>
        <p class="m-par">{{ $t('privacyPolicy.par11Line2A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par11Line2B') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par11Line2C') }}<span class="f-i">Prêt en ligne</span>.</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par12Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('privacyPolicy.par12Line1') }}</p>

        <h3 class="m-fsi-par">{{ $t('privacyPolicy.par13Subtitle') }}</h3>
        <p class="pb-50 m-txt-top">{{ $t('privacyPolicy.par13Line1A') }}<span class="f-i">Prêt en ligne</span>{{ $t('privacyPolicy.par13Line1B') }}</p>

      </div>
    </v-container>

    <Banner/>
    <Footer/>

  </div>
  
</template>
<style scoped>
  .headerHeight{height: 104px;}
  .m-txt-top {margin-top:10px;}
  .m-fsi-par {margin-top:40px; font-size: calc(10px + 0.5vw); font-style: italic;}
  ul { list-style: none; margin-left: 0; padding-left: 1.2em; text-indent: -1.2em; }
  li:before { content: "-"; display: block; float: left; width: 1.2em; }
  @media handheld, only screen and (max-width: 1264px) {
    .headerHeight {height: 0px !important;}
  }
  @media handheld, only screen and (max-width: 700px) {
    .m-fsi-par {margin-top:40px; font-size: calc(16px + 0.5vw); font-style: italic;}
    p { font-size: calc(12px + 0.5vw); }
    ul { font-size: calc(12px + 0.5vw); }
    .headerHeight {height: 0px !important;}
  }
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import PrivacyPolicyTranslations from '../../public/Resource/I18N/Screen_PrivacyPolicy.json';

export default {
  name: 'PrivacyPolicy',
  components: {
    Header,
    Footer,
    Banner
  },
  mounted() {
  this.$i18n.mergeLocaleMessage('fr', PrivacyPolicyTranslations.fr);
  this.$i18n.mergeLocaleMessage('en', PrivacyPolicyTranslations.en);

},
}
</script>