<template>

  <div class="">
    <Header/>
    <div class="headerHeight"></div>

    <v-container class="mt-8">
      <v-row>
        <v-col cols="1" lg="4" class="borderTop">
          <div class=""></div>
        </v-col>
        <v-col cols="10" lg="4" class="pa-0">
          <div class="">
            <h1 class="fs-26 f-i txt-c">{{ $t('legalNotice.title') }}</h1>
          </div>
        </v-col>
        <v-col cols="1" lg="4" class="borderBot">
          <div class=""></div>
        </v-col>
      </v-row>
      <div class="fs-8 line-h-1-2 txt-j mt-16">
        <p><scan class="f-primary f-i">Prêt en ligne</scan>{{ $t('legalNotice.line1') }}(<a><router-link to="/" class="link">http:www.pretsenligne.ca</router-link></a>).</p>
        <h2 class="f-i f-bold m-par fs-12">{{ $t('legalNotice.par1Subtitle') }}</h2>
        <p class="m-txt-top">{{ $t('legalNotice.par1Line1') }}</p>
        <p class="m-par">{{ $t('legalNotice.par1Line2') }}</p>
        <p class="m-par">{{ $t('legalNotice.par1Line3') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par2Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par2Line1') }}</p>
        <p class="m-par">{{ $t('legalNotice.par2Line2') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par3Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par3Line1') }}</p>
        <p class="m-par">{{ $t('legalNotice.par3Line2A') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par3Line2B') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par3Line2C') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par4Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par4Line1') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par5Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par5Line1A') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par5Line1B') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par5Line1C') }}</p>
        <p class="m-par">{{ $t('legalNotice.par5Line2') }}</p>
        <p class="m-par">{{ $t('legalNotice.par5Line3') }}</p>
        <p class="m-par">{{ $t('legalNotice.par5Line4') }}</p>
        <p class="m-par">{{ $t('legalNotice.par5Line5') }}</p>
        <p class="m-par">{{ $t('legalNotice.par5Line6') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par6Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par6Line1') }}</p>
        <p class="m-par">{{ $t('legalNotice.par6Line2') }}</p>
        <p class="m-par">{{ $t('legalNotice.par6Line3') }}</p>
        <p class="m-par">{{ $t('legalNotice.par6Line4') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par7Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par7Line1') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par8Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par8Line1A') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par8Line1B') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par9Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par9Line1') }}</p>
        <p class="m-par">{{ $t('legalNotice.par9Line2') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par10Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par10Line1') }}</p>
        <p class="m-par">{{ $t('legalNotice.par10Line2') }}</p>
        <ul class="m-par">
          <li>{{ $t('legalNotice.par10Line3') }}</li>
          <li>{{ $t('legalNotice.par10Line4') }}</li>
          <li>{{ $t('legalNotice.par10Line5') }}</li>
          <li>{{ $t('legalNotice.par10Line6') }}</li>
          <li>{{ $t('legalNotice.par10Line7') }}</li>
          <li>{{ $t('legalNotice.par10Line8') }}</li>
          <li>{{ $t('legalNotice.par10Line9') }}</li>
        </ul>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par11Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par11Line1') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par12Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par12Line1A') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par12Line1B') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par13Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par13Line1A') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par13Line1B') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par14Subtitle') }}</h3>
        <p class="m-txt-top">{{ $t('legalNotice.par14Line1A') }}<scan class="f-i">Prêt en ligne</scan>{{ $t('legalNotice.par14Line1B') }}</p>

        <h3 class="m-fsi-par">{{ $t('legalNotice.par15Subtitle') }}</h3>
        <p class="pb-50 m-txt-top">{{ $t('legalNotice.par15Line1') }}</p>

      </div>
    </v-container>
    <Banner/>
    <Footer/>

  </div>
  
</template>
<style scoped>
  .headerHeight{height: 104px;}
  .m-par {margin-top:16px;}
  .m-txt-top {margin-top:4px;}
  .m-fsi-par {margin-top:40px; font-size: calc(10px + 0.5vw); font-style: italic;}
  ul { list-style: none; margin-left: 0; padding-left: 1.2em; text-indent: -1.2em; }
  li:before { content: "-"; display: block; float: left; width: 1.2em; }
  @media handheld, only screen and (max-width: 1264px) {
    .headerHeight {height: 0px !important;}
  }
  @media handheld, only screen and (max-width: 700px) {
    .m-fsi-par {margin-top:40px; font-size: calc(16px + 0.5vw); font-style: italic;}
    p {
      font-size: calc(12px + 0.5vw);
    }
    ul {
      font-size: calc(12px + 0.5vw);
    }
    .headerHeight {height: 0px !important;}
  }

</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import LegalNoticeTranslations from '../../public/Resource/I18N/Screen_LegalNotice.json'

export default {
  name: 'LegalNotice',
  components: {
    Header,
    Footer,
    Banner
  },
  mounted() {
    this.$i18n.mergeLocaleMessage('fr', LegalNoticeTranslations.fr);
    this.$i18n.mergeLocaleMessage('en', LegalNoticeTranslations.en);
  },
}
</script>