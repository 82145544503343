<template>
    <v-container class="mb-10 mt-10" fluid>
        <v-row no-gutters class="grid-pad">
          <v-col cols="12" class="mb-4">
            <div class="txt-c " style="font-size: 1.2vw; text-align: center;" outlined tile>
              <h2 class="fs-22 f-bold">{{ $t('step4.titleLine1') }}<br>{{ $t('step4.titleLine2') }}<br>{{ $t('step4.titleLine3') }}&nbsp;?</h2>
            </div>
          </v-col>

          <!--1-->
          <v-col cols="12" sm="11" md="6" class="hover-card">
            <div class="v-primary-light-7 rounded-circle txt-c stepW50">
              <span class="fs-34 f-bold f-white">1</span>
            </div>
            <div class="border-primary ml-12 mt-n6 mb-6 mr-16"></div>

            <div class="ml-16">
              <h3 class="fs-16">{{ $t('step4.phase1Line1') }}</h3>
              <div class="f-secondary">
                <h4 class="fs-10">{{ $t('step4.phase1Line2') }}<router-link  @click.native="Form()" to="/" class="textBox">{{ $t('step4.phase1Line3') }}</router-link> <br/>{{ $t('step4.phase1Line4') }}&nbsp;{{ $t('step4.phase1Line5') }}</h4>
              </div>
            </div>
          </v-col>

          <!--2-->
          <v-col cols="12" sm="11" md="6" class="hover-card">
            <div class="v-primary-light-8 rounded-circle txt-c stepW50">
              <span class="fs-34 f-bold f-white">2</span>
            </div>
            <div class="border-primary ml-12 mt-n6 mb-6 mr-16"></div>

            <div class="ml-16">
              <h3 class="fs-16">{{ $t('step4.phase2Line1') }}</h3>
              <div class="f-secondary">
                <h4 class="fs-10">{{ $t('step4.phase2Line2') }}<br/>{{ $t('step4.phase2Line3') }}&nbsp;{{ $t('step4.phase2Line4') }}</h4>
              </div>
            </div>
          </v-col>

          <!--3-->
          <v-col cols="12" sm="11" md="6" class="hover-card">
            <div class="v-primary-light-9 rounded-circle txt-c stepW50">
              <span class="fs-34 f-bold f-white">3</span>
            </div>
            <div class="border-primary ml-12 mt-n6 mb-6 mr-16"></div>

            <div class="ml-16">
              <h3 class="fs-16">{{ $t('step4.phase3Line1') }}</h3>
              <div class="f-secondary">
                <h4 class="fs-10">{{ $t('step4.phase3Line2') }}<br/>{{ $t('step4.phase3Line3') }}<br/>{{ $t('step4.phase3Line4') }}&nbsp;{{ $t('step4.phase3Line5') }}</h4>
              </div>
            </div>
          </v-col>

          <!--4-->
          <v-col cols="12" sm="11" md="6" class="hover-card">
            <div class="v-primary rounded-circle txt-c stepW50">
              <span class="fs-34 f-bold f-white">4</span>
            </div>
            <div class="border-primary ml-12 mt-n6 mb-6 mr-16"></div>

            <div class="ml-16">
              <h3 class="fs-16">{{ $t('step4.phase4Line1') }}</h3>
              <div class="f-secondary">
                <h4 class="fs-10">{{ $t('step4.phase4Line2') }}<br/>{{ $t('step4.phase4Line3') }}&nbsp;{{ $t('step4.phase4Line4') }}</h4>
              </div>
            </div>
          </v-col>

        </v-row>
    </v-container>
</template>


<style scoped>

  .hover-card:hover { opacity: 0.9; color: #000000; }
  .stepW50 {width:50px;}
  .fs-34 {font-size: 34px !important;}
  .textBox {color: rgb(var(--primary)); text-decoration:none;}
  @media handheld, only screen and (max-width: 700px) {
    .mr-16 { margin-right: 0px !important; }
  }

</style>

<script>
  export default {
    name: 'Step4',

    data: () => ({
    
    }),
    methods:{
      currentLang() {
          return this.$i18n.locale;
      },
      Form() {
        let lang = this.currentLang();

        console.log(lang);

        if(lang == 'en'){ window.open('https://applications.pretsenligne.ca/EN/', '_blank'); }
        else{ window.open('https://applications.pretsenligne.ca/', '_blank'); }
      },
    },
    component: {

    }
  }
</script>