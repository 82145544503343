<template>

  <div>
    <Header/>
    <div class="headerHeight"></div>
    <!--Start Dialog-->
    <v-dialog v-model="this.dialog.start" persistent absolute>
      <v-card class="borderDialogStart">
        
        <v-card-text>
          <a @click="closeDialog();"><v-icon class="iconMb" style="font-size: 30px; right:26px; position: fixed; color:black;">mdi-close</v-icon></a>
          <h2 class="text-h5 f-i txt-c">{{ $t('forms.dialogTitle') }}</h2>
          <v-row class="px-0">
            <v-col cols="2" class="px-0"><v-checkbox class="pr-0" v-model="conditions.one"></v-checkbox></v-col>
            <v-col cols="10"><div class="checkboxText">{{ $t('forms.firstQuestion') }}&nbsp;?</div></v-col>
          </v-row>
          <v-row class="px-0">
            <v-col cols="2" class="px-0"><v-checkbox class="pr-0" v-model="conditions.two"></v-checkbox></v-col>
            <v-col cols="10"><div class="checkboxText">{{ $t('forms.secondQuestion') }}&nbsp;?</div></v-col>
          </v-row>
          <v-row class="px-0">
            <v-col cols="2" class="px-0"><v-checkbox class="pr-0" v-model="conditions.three"></v-checkbox></v-col>
            <v-col cols="10"><div class="checkboxText">{{ $t('forms.thirdQuestion') }}&nbsp;?</div></v-col>
          </v-row>
          <v-row class="px-0">
            <v-col cols="2" class="px-0"><v-checkbox class="pr-0" v-model="conditions.four"></v-checkbox></v-col>
            <v-col cols="10"><div class="checkboxText">{{ $t('forms.fourQuestion') }}&nbsp;?</div></v-col>
          </v-row>
        
          <p class="checkboxNotice">{{ $t('forms.dialogdescription') }}<br>{{ $t('forms.dialogdescription2') }}</p>
          <p id="conditionInvalid" class="f-red checkboxNotice hide">{{ $t('forms.dialogWarning') }}</p>
        </v-card-text>
        <v-card-actions>
          <h2 class="center f-primary" style="cursor: pointer;" text @click="validateCondition()">{{ $t('forms.NextBtn') }}</h2>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Finish Dialog-->
    <v-dialog v-model="this.dialog.finish" persistent absolute>
      <v-card class="borderDialog">
        <v-card-text>
          <p class="txt-c fs-14">{{ $t('forms.finishDialogTitle') }}</p>
          <a @click="this.finish()" href="/" class="f-primary txt-c fs-18 mt-1">{{ $t('forms.finishDialogParagraph') }}</a>
          <p class="txt-c fs-16 mt-10 line-h-100">{{ $t('forms.finishDialogFollow') }}<br>{{ $t('forms.finishDialogFollow2') }}</p>   
          <a @click="this.finish()" href="/"><p class="f-secondary txt-c mt-10 fs-12">{{ $t('forms.finishDialogBack') }}</p></a>   
        </v-card-text>
      </v-card>
    </v-dialog>
      <!--waiting Dialog-->
    <v-dialog v-model="this.dialog.waiting" persistent absolute>
      <v-card class="borderDialog">
        <v-card-text>
          <p class="txt-c fs-14">{{ $t('forms.waitDialog') }}.</p>
          <p class="f-primary txt-c fs-18 mt-1">{{ $t('forms.waitDialogThanks') }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Exceded time Dialog-->
    <v-dialog v-model="this.dialog.excededTime" persistent absolute>
      <v-card class="borderDialog">
        <v-card-text>
          <p class="txt-c fs-14 line-h-100">{{ $t('forms.excededDialog') }}<br> {{ $t('forms.excededDialog2') }}.</p>
          <router-link class="" to="/"><p class="f-primary txt-c fs-18 mt-5">{{ $t('forms.redoneDialog') }}</p></router-link>
          <p class="txt-c fs-16 mt-10">{{ $t('forms.waitDialogThanks') }}</p>  
          <a @click="this.finish()" href="/"><p class="f-secondary txt-c mt-10 fs-12">{{ $t('forms.finishDialogBack') }}</p></a>      
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Error Dialog-->
    <v-dialog v-model="this.dialog.error" persistent absolute>
      <v-card class="borderDialog">
        <v-card-text>
          <p class="txt-c fs-14">{{ $t('forms.errorDialog') }}.</p>
          <router-link to="/"><p class="f-primary txt-c fs-18 mt-1">{{ $t('forms.redoneDialog') }}</p></router-link>
          <p class="txt-c fs-16 mt-4 line-h-100">{{ $t('forms.waitDialogThanks') }}</p>
          <a @click="this.finish()" href="/"><p class="f-secondary txt-c mt-10 fs-12">{{ $t('forms.finishDialogBack') }}</p></a>        
        </v-card-text>
      </v-card>
    </v-dialog>

    <form id="regForm" action="">

      <h1 class="txt-c">{{ $t('forms.title') }}</h1>

      <!-- One "tab" for each step in the form: -->
      
      <!--Step 1 : First choose money-->
      <div class="tab">
        <h2 class="f-primary mt-10">{{ $t('forms.firstPageTitle') }} ?</h2>
        <v-btn-toggle v-model="item.moneyLoan" mandatory>
          <v-btn class="v-secondary" v-for="n in moneyAvailable" :key="n">{{n+'$'}}</v-btn>
        </v-btn-toggle>
      </div>

      <!--Step 2 :personnal information-->
      <div class="tab">
        <h2 class="f-primary mt-5">{{ $t('forms.secondPageTitle') }} :</h2>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.FirstName') }} :</p></v-col>
            <v-col cols="12"><input  v-model="item.firstName"></v-col>
          </v-row>
        </div>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.LastName') }} :</p></v-col>
            <v-col cols="12"><input v-model="item.lastName"></v-col>
          </v-row>
        </div>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.Email') }} :</p></v-col>
            <v-col cols="12"><input v-model="item.email"></v-col>
          </v-row>
        </div>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.Phone') }} :</p></v-col>
            <v-col cols="12"><input v-model="item.phone"></v-col>
          </v-row>
        </div>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.Address') }} :</p></v-col>
            <v-col cols="12"><input v-model="item.address"></v-col>
            <v-col cols="12" class="describeAdress"><p>{{ $t('forms.AddressDescription') }}</p></v-col>
          </v-row>
        </div>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.Job') }} :</p></v-col>
            <v-col cols="12"><input v-model="item.Job"></v-col>
            <v-col cols="12" class="describeJob"><p>{{ $t('forms.JobDescription') }}</p></v-col>
          </v-row>
        </div>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.employerName') }} :</p></v-col>
            <v-col cols="12"><input v-model="item.employerName"></v-col>
          </v-row>
        </div>
        <div class="pa-1">
          <v-row>
            <v-col cols="12" class="padbCol"><p>{{ $t('forms.employerPhone') }} :</p></v-col>
            <v-col cols="12"><input v-model="item.employerPhone"></v-col>
          </v-row>
        </div>
      </div>

      <!--Step 3 : Bank Document needed-->
      <div class="tab">
        <h2 class="f-primary mt-10 txt-c">{{ $t('forms.thirdPageTitle') }}</h2>
        <p class="txt-c mt-5">{{ $t('forms.thirdPageParagraph') }}<span class="f-bold">{{ $t('forms.thirdPageParagraph2') }}</span>. <br>{{ $t('forms.thirdPageParagraph3') }}</p>
        <p class="txt-c mt-5 stepTextSizeModif">{{ $t('forms.thirdPageParagraph4') }}<span class="f-bold">{{ $t('forms.thirdPageParagraph5') }}</span>{{ $t('forms.thirdPageParagraph6') }}<br>{{ $t('forms.thirdPageParagraph9') }}<span class="f-bold">{{ $t('forms.thirdPageParagraph7') }}</span>{{ $t('forms.thirdPageParagraph8') }}</p>
        <div class="pa-1 mt-5"><p class="stepCol">{{ $t('forms.firstFile') }} : </p><p class="stepCol"><span class="describeJob">{{ $t('forms.mandatory') }}</span></p><v-file-input label="File input" filled prepend-icon="mdi-camera" accept=".png, .jpg, .jpeg" v-model="item.Document1"></v-file-input></div>
        <div class="pa-1"><p class="stepCol">{{ $t('forms.secondFile') }} : </p><v-file-input id="itemDoc2" label="File input"  prepend-icon="mdi-camera" accept=".png, .jpg, .jpeg"  v-model="item.Document2"></v-file-input></div>
        <div class="pa-1"><p class="stepCol">{{ $t('forms.thirdFile') }} : </p><v-file-input id="itemDoc3" label="File input"  prepend-icon="mdi-camera" accept=".png, .jpg, .jpeg" v-model="item.Document3"></v-file-input></div>
        <div class="pa-1"><p class="stepCol"><b>{{ $t('forms.specimenCheck') }}</b> :</p><p class="stepCol"><span class="describeJob">{{ $t('forms.mandatory') }}</span></p><v-file-input id="itemDoc4" label="File input"  prepend-icon="mdi-camera" accept=".png, .jpg, .jpeg, .pdf" v-model="item.Document4"></v-file-input></div>
        <p class="txt-c mt-5 stepTextSizeModif">{{ $t('forms.thirdPageDescription') }}</p>
      </div>

      <!--Step 4 : identification Document Needed-->
      <div class="tab">
        <h2 class="f-primary mt-10 txt-c">{{ $t('forms.fourPageTitle') }}</h2>
        <p class="txt-c mt-5">{{ $t('forms.fourPageDescription') }}<br> {{ $t('forms.fourPageDescription2') }}</p>
        <div class="pa-1 mt-5"><p class="stepCol">{{ $t('forms.DriverLicense') }} : </p><v-file-input id="FileOne" label="File input" filled prepend-icon="mdi-camera" accept=".png, .jpg, .jpeg"  v-model="item.driverLicense"></v-file-input></div>
        <div class="pa-1"><p class="stepCol">{{ $t('forms.socialSecurityNumber') }} : </p><input id="itemSSN" placeholder="" v-model="item.socialSecurityNumber"></div>
        <p class="txt-c mt-5 stepTextSizeModif">{{ $t('forms.fourPageLastDescription') }}</p>
      </div>

      <!-- <button class="" type="button"  @click="TestEmail()">test email</button> -->

      <div>
        <div>
          <button class="center txt-c" type="button" id="nextBtn" @click="this.nextPrev(1)">{{ $t('forms.NextBtn') }}</button>
        </div>
      </div>

      <!-- Circles which indicates the steps of the form: -->
      <div class="stepBox">
        <span class="step">1</span>
        <span class="step">2</span>
        <span class="step">3</span>
        <span class="step">4</span>
      </div>

    </form> 
    <Banner/>
    <Footer/>

  </div>
  
</template>
<style scoped>
  .smallTxt {font-size: 12px;}
  a:link { text-decoration: none; }
  .headerHeight{height: 104px;}
  .v-dialog {overflow: hidden; position: absolute; z-index: 100000;}
  .padbCol {padding-bottom: 0px;}
  .borderDialog { border-radius:25px !important; border: 5px solid rgb(var(--secondary)); }
  .borderDialogStart { border-radius:50px !important; border: 5px solid rgb(var(--secondary)); height:auto; }
  .describeAdress { margin: -25px 0px 0px 10px; padding-left:10px; opacity:0.5; font-size:12px; }
  .describeJob { margin: -25px 0px 0px 10px; opacity:0.5; font-size:12px; }
  .v-checkbox {height: 40px;overflow: hidden;padding-left: 12px;}
  .v-btn-group {display: unset !important;}
  .v-btn{margin-bottom: 10px;width: 100px;height: 50px !important;border-radius: 10px !important;color: black;}
  .v-btn:hover{background-color: rgba(var(--primary),0.8) !important;}
  .v-btn--selected { background-color: rgb(var(--primary)) !important; -moz-box-shadow:    inset 0 0 10px black !important;-webkit-box-shadow: inset 0 0 10px black !important; box-shadow: inset 0 0 10px black !important;}
  .checkboxText {color : grey;margin-top: 18px;font-size: calc(14px);}
  .checkboxNotice {text-align: center;margin-top : 20px;font-size: calc(12px);}
  #regForm {background-color: #ffffff;margin: 0px auto;font-family: Raleway;padding: 40px;width: 70%;min-width: 300px;}
  input {padding: 10px;width: 100%;font-size: 17px;font-family: Raleway; border: 1px solid #aaaaaa;}
  input.invalid {background-color: #ffdddd;}
  .tab {display: none;}  /* Hide all steps by default: */
  button { background-color: rgb(var(--primary));color: #ffffff;border: none;padding: 0px 0px;font-size: 18px;cursor: pointer;display: block;margin-left: auto;margin-right: auto;font-weight: 700;}
  #nextBtn{ background-color: rgb(var(--primary)); color: #ffffff; border: none; padding: 0px 20px; font-size: 18px; cursor: pointer; font-weight: 700; border-radius: 30px; margin: 50px 0px 0px 0px; display: block; margin-left: auto; margin-right: auto; }
  #prevBtn{ background-color: rgb(var(--primary)); color: #ffffff; border: none; padding: 10px 20px; font-size: 18px; cursor: pointer; font-weight: 700; border-radius: 10px; display: inline-block; visibility: hidden; }
  .v-btn input {border: none;text-align: center;}
  button:hover {opacity: 0.8;}
  #prevBtn {background-color: #bbbbbb;}
  /* Make circles that indicate the steps of the form: */
  .step { height: 25px; width: 25px; margin: 0 2px; background-color: rgb(var(--primary)); border: none; border-radius: 50%; display: inline-block; opacity: 0.5; }
  .step.active {opacity: 1;}

  /* Mark the steps that are finished and valid: */
  .step.finish {background-color: rgba(var(--secondary),0.8) !important;}
  .stepBox { text-align:center;margin-top:40px; }
  .stepTextSizeModif { font-size:14px }
  .hide{display: none;}
  .stepCol {display:inline-block;width:75%;}

  @media handheld, only screen and (max-width: 500px) {
  .v-dialog { overflow: hidden;}
  .v-checkbox { height: 40px; overflow: hidden; padding-left: -8px; }
  .checkboxNotice { font-size: 8px; }
  }

  @media handheld, only screen and (max-width: 700px) {
    .v-dialog { overflow: hidden; }
    .checkboxText { margin-top: 18px; font-size: 13px; }
    .checkboxNotice { font-size: 9px; }
    #regForm { background-color: #ffffff; margin: 0px auto; font-family: Raleway; padding: 5px; width: 100%; min-width: 300px; }
    .v-checkbox { height: 40px; overflow: hidden; padding-left: 0px; }
    input { padding: 10px; width: 100%; font-size: 17px; font-family: Raleway; border: 1px solid #aaaaaa; }
  }
  @media handheld, only screen and (max-width: 1264px) {
    .headerHeight { height: 40px !important; }
  }
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import FormsTranslations from '../../public/Resource/I18N/Screen_Forms.json';

export default {
  name: 'Form',
  data: () => ({
    currentTab : 0,
    conditions: {
      one: false,
      two: false,
      three: false,
      four: false,
    },
    item: {
      moneyLoan: 0,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      address: null,
      Job: null,
      employerName: null,
      employerPhone: null,
      Document1:[],
      Document2:[],
      Document3:[],
      Document4:[],
      socialSecurityNumber: null,
      driverLicense:[],
    },
    moneyAvailable:null,
    files:[],
    filesLength:0,
    dialog: {
      start: false,
      finish: false,
      waiting: false,
      excededTime: false,
      error: false,
    },
    key: "a", //need to add key
  }),
  mounted: async function () {
    this.getListLoanMoneyAvailable();
    this.selectedBtn(250);
    this.showTab(this.currentTab);
    this.dialog.start = true;
    this.$i18n.mergeLocaleMessage('fr', FormsTranslations.fr);
    this.$i18n.mergeLocaleMessage('en', FormsTranslations.en);
  },
  methods: {
    showTab (n) {
      // This function will display the specified tab of the form ...
      var x = document.getElementsByClassName("tab");
      x[n].style.display = "block";
      // ... and fix the Previous/Next buttons:
      if (n == (x.length - 1)) {
        if(this.$i18n.locale == 'en'){document.getElementById("nextBtn").innerHTML = "submit";}else{document.getElementById("nextBtn").innerHTML = "Envoyé";}
      } else {
        if(this.$i18n.locale == 'en'){document.getElementById("nextBtn").innerHTML = "Continue";} else {document.getElementById("nextBtn").innerHTML = "Continuer";}
        
      }
      // ... and run a function that displays the correct step indicator:
      this.fixStepIndicator(n)
    },
    nextPrev (n) {
      // This function will figure out which tab to display
      var x = document.getElementsByClassName("tab");
      // Exit the function if any field in the current tab is invalid:
      if (n == 1 && !this.validateForm()) return false;
      // Hide the current tab:
      x[this.currentTab].style.display = "none";
      // Increase or decrease the current tab by 1:
      this.currentTab = this.currentTab + n;
      // if you have reached the end of the form... :
      if (this.currentTab >= x.length) {
        //...the form gets submitted:
        var form = document.getElementById("regForm");
        this.dialog.waiting = true;
        this.SendForm();
        return false;
      }
      // Otherwise, display the correct tab:
      this.showTab(this.currentTab);
    },
    validateForm () {
      // This function deals with validation of the form fields
      var x, y, i, valid = true;
      x = document.getElementsByClassName("tab");
      y = x[this.currentTab].getElementsByTagName("input");
      // A loop that checks every input field in the current tab:
      for (i = 0; i < y.length; i++) {
        // If a field is empty...
        if (y[i].value == "") {
          // add an "invalid" class to the field: 
          if(y[i].id == "itemDoc2" || y[i].id == "itemDoc3") {continue;}
          if(y[i].id == "itemSSN") {continue;}
          y[i].className += " invalid";
          // and set the current valid status to false:
          valid = false;
        }
      }
      // If the valid status is true, mark the step as finished and valid:
      if (valid) {
        document.getElementsByClassName("step")[this.currentTab].className += " finish";
      }
      return valid; // return the valid status
    },
    fixStepIndicator (n) {
      // This function removes the "active" class of all steps...
      var i, x = document.getElementsByClassName("step");
      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
      }
      //... and adds the "active" class to the current step:
      x[n].className += " active";
    },
    selectedBtn(n){
      switch(n){
        case 250:
          this.moneyLoan = 250;
          console.log(this.moneyLoan);
          break;
        case 500:
          this.moneyLoan = 500;
          console.log(this.moneyLoan);
          break;
        case 750:
          this.moneyLoan = 750;
          console.log(this.moneyLoan);
          break;
        case 1000:
          this.moneyLoan = 1000;
          console.log(this.moneyLoan);
          break;
      }
    },
    validateCondition(){
      var x;
      if(this.conditions.one && this.conditions.two && this.conditions.three && this.conditions.four){
        this.dialog.start = false;
      }else {
        var x = document.getElementById("conditionInvalid"); 
        x.classList.remove("hide");
        
      }
    },
    SendForm: async function(){
      let formData = new FormData();
      switch (this.item.moneyLoan) {
        case 0:
          formData.append("loanMoney", "250");
          break;
        case 1:
          formData.append("loanMoney", "500");
          break;
        case 2:
          formData.append("loanMoney", "750");
          break;
        case 3:
          formData.append("loanMoney", "1000");
          break;
        default:
          formData.append("loanMoney", "250");
          break;
      }      
      formData.append("firstName", this.item.firstName);
      formData.append("lastName", this.item.lastName);
      formData.append("email", this.item.email);
      formData.append("phone", this.item.phone);
      formData.append("address", this.item.address);
      formData.append("actualJob", this.item.Job);
      formData.append("employerName", this.item.employerName);
      formData.append("employerPhone", this.item.employerPhone);
      formData.append("sociaNumber", this.item.socialSecurityNumber);

        // HTML file input, chosen by use
      if(this.item.Document1){this.files[this.filesLength] = this.item.Document1[0]; this.filesLength++;}
      if(this.item.Document2){this.files[this.filesLength] = this.item.Document2[0]; this.filesLength++;}
      if(this.item.Document3){this.files[this.filesLength] = this.item.Document3[0]; this.filesLength++;}
      if(this.item.Document4){this.files[this.filesLength] = this.item.Document4[0]; this.filesLength++;}
      if(this.item.driverLicense){this.files[this.filesLength] = this.item.driverLicense[0]; this.filesLength++;}
      
      this.files.forEach(element => {
        formData.append("file", element);
      });            

      let api = $App.url.api + 'sendFormByEmail';//
      let response = await fetch(api, {
              method: 'POST',
              mode: 'cors', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                  'Authorization': this.key,
              },
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *client
              body: formData
          });

          if (await response.ok) {
              if (await response.status !== 204) {
                this.dialog.waiting = false;
                this.dialog.finish = true;
              }
          } else if(await response.Unauthorized){
            this.dialog.excededTime = true;
          } else {
              this.dialog.waiting = false;
              this.dialog.error = true;
          };
    },
    finish(){
      this.dialog.finish = false;
    },
    closeDialog(){
      this.dialog.start = false;
      this.dialog.finish = false;
      this.dialog.waiting = false;
      this.dialog.excededTime = false;
      this.dialog.error = false;
      this.$router.push("/");
    },
    getListLoanMoneyAvailable: async function(){
      let api = '/Resource/amountAvailable.json';
      
      let response = await fetch(api, {
              method: 'GET',
              mode: 'cors', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {},
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *client
          });

          if (await response.ok) {
              if (await response.status !== 204) {
                this.key = response.headers.get('Authorization'); //need to comment if localhost  
                let data = await response.json();
                this.moneyAvailable = data.loanMoneyAvailable;
              }
          } else {
              this.dialog.error = true;
          };
          
    },
    TestEmail: async function() {

      let api = $App.url.api + 'TestEmail';//
      console.log(api);
      let response = await fetch(api, {
              method: 'POST',
              mode: 'cors', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                  'Authorization': this.key,
              },
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *client
          });

          if (await response.ok) {
              if (await response.status !== 204) {
              }
          } else { 
              console.log('error');
          }

    }
  },

  components: {
    Header,
    Footer,
    Banner
  }
}
</script>